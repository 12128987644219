@import '../node_modules/@amzn/node-js-apn-feedback-common-assets/dist/style.css';
html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

.main-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.main-container main.awsui-app-layout__container {
  background-color: #232f3e;
}

awsui-modal .awsui-modal-container {
  z-index: 5000 !important;
}
